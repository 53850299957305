<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Dokument</h3>
                    <h2 class="">{{campaign.title}}</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7" v-if="campaign">
            <div class="row">
                <div class="col-xl-6 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="row" v-if="campaign">
                                    <div class="col-lg-12">
                                        <template>
                                            <div class="mb-4">
                                                <h6 class="heading-small text-muted mb-2">Názov dokumentu</h6>
                                                <a-input alternative=""
                                                         input-classes="form-control-alternative"
                                                         v-model="campaign.title"
                                                />
                                            </div>
                                            <div class="mb-4">
                                                <h6 class="heading-small text-muted mb-2">Druh obsahu</h6>
                                                <a-select  class=" w-100" v-model="campaign.type" :options="types"/>
                                            </div>
                                            <div class="mb-4" v-if="campaign.type === 2">
                                                <h6 class="heading-small text-muted mb-2">Typ obsahu</h6>
                                                <a-select  class=" w-100" v-model="campaign.contenttype" :options="contenttypes"/>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="text-right">
                                  <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>

                    <a-card class="mt-4" v-if="campaign.data">
                        <div class="pickelement">
                            <div class="mb-4">
                                <h6 class="heading-small text-muted mb-2">Objednávka</h6>
                            <a-select mode="multi" class="w-100" v-model="selectedElement">
                                <a-select-option v-for="(op, indd) in campaign.data" :key="indd">{{indd}} : {{op}}</a-select-option>
                                <a-select-opt-group  :label="'Doplnkové dáta'">
                                    <a-select-option v-for="(subproduct,slug) in campaign.arrays.data" :key="'data|' + slug">{{slug}}: {{subproduct}}</a-select-option>
                                </a-select-opt-group>
                            </a-select>
                            </div>
                            <div class="mb-4" v-for="(products,indd) in campaign.products">
                                <div v-if="selectedElement === products.slug">
                                <h6 class="heading-small text-muted mb-2">Priradené produkty {{products.title}}</h6>
                                <a-select mode="multiple" class="w-100" v-model="selectedProductElement">
                                    <a-select-option v-for="(op, indd) in products.fields" :key="op.slug" >{{op.title}}</a-select-option>
                                    <a-select-opt-group v-for="(subproduct,slug) in products.subproducts" :label="'podprodukt ' + products.title">
                                        <a-select-option v-for="op in subproduct" :key="products.slug + '|' + slug + '|' + op.value" >{{products.title}}: {{op.label}}</a-select-option>
                                    </a-select-opt-group>
                                </a-select>
                                    <h6 class="heading-small text-muted mb-2">Opakovanie</h6>
                                    <a-select mode="multi" class="w-100" :options="repetitions" v-model="repetition"/>
                                </div>
                            </div>

                            <div class="mb-4 w-25 float-left">
                                <h6 class="heading-small text-muted mb-2">Strana</h6>
                                <a-input-number v-model="selectedPage"
                                                input-classes="form-control-alternative" :defaultValue="1" :min="1" :max="JSON.parse(campaign.pdf).jpg.length"></a-input-number>
                            </div>
                            <div class="mb-4 w-25 float-left">
                                <h6 class="heading-small text-muted mb-2">Veľkosť</h6>
                                <a-input-number v-model="selectedFontSize"
                                                input-classes="form-control-alternative" :min="6" :max="12"></a-input-number>
                            </div>
                            <div class="mb-4 w-25 float-left">
                                <h6 class="heading-small text-muted mb-2">Zarovnanie</h6>
                                <a-select mode="multi" class="w-100" :options="aligns" v-model="align">
                                </a-select>
                            </div>
                            <div class="text-right"> <h6 class="heading-small text-muted mb-2">&nbsp;</h6>
                                <a-button type="primary" icon="fad fa-layer-plus" @click="addelement" class="ml-3"  title="Pridať">pridať</a-button>
                            </div>
                        </div>

                    </a-card>
                    <a-card class="mt-4">
                        <div class="pickelement">
                            <div class="mb-4">
                                <h6 class="heading-small text-muted mb-2">Päta strany</h6>

                            <a-input v-model="addTextfield"></a-input>
                            </div>
                               <div class="text-right"> <h6 class="heading-small text-muted mb-2">&nbsp;</h6>
                                <a-button type="primary" icon="fad fa-layer-plus" @click="addfooter" class="ml-3"  title="Pridať">pridať</a-button>
                            </div>
                        </div>

                    </a-card>
                </div>

                <a-modal :title="'Úprava pdf poľa ' + (editElement.element ? editElement.element : editElement.product)" centered  v-model="editModal" v-if="editModal" @ok="editModal = false;" >
                    <div class="mb-4 w-25 float-left">
                        <h6 class="heading-small text-muted mb-2">Strana</h6>
                        <a-input-number v-model="editElement.page"
                                        input-classes="form-control-alternative w-100" :defaultValue="1" :min="1" :max="JSON.parse(campaign.pdf).jpg.length"></a-input-number>
                    </div>
                    <div class="mb-4 w-25 float-left">
                        <h6 class="heading-small text-muted mb-2">Veľkosť</h6>
                        <a-input-number v-model="editElement.size"
                                        input-classes="form-control-alternative w-100" :min="6" :max="12"></a-input-number>
                    </div>
                    <div class="mb-4 w-25 float-left">
                        <h6 class="heading-small text-muted mb-2">Predpona</h6>
                        <a-input v-model="editElement.prefix"
                                        input-classes="form-control-alternative" style="width:90px"></a-input>
                    </div>
                    <div class="mb-4 w-25 float-left">
                        <h6 class="heading-small text-muted mb-2">Prípona</h6>
                        <a-input v-model="editElement.suffix"
                                        input-classes="form-control-alternative" style="width:90px"></a-input>
                    </div>
                    <div class="mb-4 ">
                        <h6 class="heading-small text-muted mb-2">Zarovnanie</h6>
                        <a-select mode="multi" class="w-100" :options="aligns" v-model="editElement.align">
                        </a-select>
                    </div>
                    <template slot="footer">
                        <a-button type="primary" key="back" @click="editModal = false;">
                            Upraviť
                        </a-button>
                    </template>

                </a-modal>

                <div class="col-lg-6 document-editor">
                    <vue-draggable-resizable style="color:#ff6d02"  v-for="(addpr,ind) in adde" :x="0" :y="0" :style="'font-size:' + selectedFontSize * 1.5 + 'px'" @dragstop="(e,f) => onDragging(e,f,id)" class-name-draggable="grababble element-holder" :h="30" :z="999" :resizable="false">
                       {{campaign.data[addpr]}} {{campaign.arrays.products[0].data[addpr]}} {{addpr}}
                        <a-button type="secondary"  class="p-0" icon="fal fa-check" @click="placelement(addpr,ind)" textColor="default" title="pridať"></a-button>
                     </vue-draggable-resizable>



                    <vue-draggable-resizable style="color:#b976ff" v-for="(added,index) in campaign.pagefooter"
                                             :style="'font-size:' + selectedFontSize * 1.5 + 'px'"  :x="added.x" :y="added.y"  @dragstop="(e,f) => changedFooterDragging(e,f,index)" class-name-draggable="grababble element-holder" :h="30" :z="999" :resizable="false">
                     <span :class="added.align">{{added.prefix}} {{added.text}} {{added.suffix}}</span>
                        <span class="action-holder">
                            <a-button type="secondary"  class="p-0 remove-button" icon="fal fa-times" @click="deletefooterelement(index)" textColor="default" title="zmazať"></a-button></span>
                    </vue-draggable-resizable>

<span v-if="campaign.setup">
                    <vue-draggable-resizable style="color:#5d9ed0" v-for="(added,index) in campaign.setup" :style="'font-size:' + added.size * 1.5 + 'px'" :x="added.x" :y="added.y" @dragstop="(e,f) => changedDragging(e,f,index)" class-name-draggable="grababble element-holder" :h="30" :z="999" :resizable="false">
                     <span  :class="added.align + ' dynamic-element'">  <span v-if="added.element" :title="expl(added.element,1) ? expl(added.element,1) : added.element">{{added.prefix}} {{campaign.arrays.data[expl(added.element,1)]}} {{campaign.data[added.element]}} {{added.suffix}}</span><span v-else  :title="expl(added.product,2) ? expl(added.product,2) : added.product">{{added.prefix}} {{campaign.arrays.products[0].data[added.product]}} {{campaign.arrays.products[0].parsed[added.product]}} {{campaign.arrays.products[0].parsed[expl(added.product,2)]}} {{added.suffix}}</span>  </span>
                      <span class="action-holder">
                          <a-button type="secondary"  class="p-0 remove-button" icon="fal fa-times-circle" @click="deleteelement(index)" textColor="default" title="zmazať"></a-button>
                            <a-button type="secondary"  class="p-0 edit-button" icon="fal fa-cog" @click="openModalEdit(index)" textColor="default" title="upraviť"></a-button>

                      </span>
                    </vue-draggable-resizable>
</span>
                    <div ref="pageHolder"><div v-for="(pdfpage,pagenumber) in JSON.parse(campaign.pdf).jpg" class="pdf-page">
                    <img :src="pdfpage"  v-if="selectedPage === pagenumber + 1 "> </div></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {dataService} from "../_services";
    import Image from "./content/Image";
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import Upload from "./content/Upload";
    import ADatePicker from "ant-design-vue/es/date-picker";
    import AInput from "ant-design-vue/es/input";

    import Multi from "./content/multi";
    import moment from 'moment'

    import 'moment/locale/sk';
    import Vue from "vue"
    import VueDraggableResizable from 'vue-draggable-resizable'
    import ATextarea from "ant-design-vue/es/input/TextArea";

    // optionally import default styles
  //  import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

    Vue.component('vue-draggable-resizable', VueDraggableResizable)

    Vue.use(FormItem);
    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }
    let id = 0;
    export default {
        name: 'document',
        props: {id},
        components: {ATextarea, Multi,AFormItem,'a-range-picker': ADatePicker.RangePicker,"a-input":AInput,Upload},
        data() {
            return {
                url: getURL(),
                colorsto: JSON.parse(localStorage.colors),
                fieldData: {},
                types:[{"value":"1","label": "Objednávka"},{"value":"2","label": "Produkt"}],
                repetitions:[{"value":"1","label": "Opakuje sa v riadku"},{"value":"2","label": "Opakuje stranu"},{"value":"3","label": "Opakuje dokument"}],
                aligns:[{"value":"left","label": "zľava"},{"value":"right","label": "sprava"},{"value":"center","label": "na stred"}],
                contenttypes:[],
                campaign: {daterange:[]},
                options:[],
                moment: moment,
                locale,
                width: 0,
                selectedPage:1,
                selectedFontSize:8,
                align:"left",
                selectedElement:"",
                addTextfield:"",
                editElement:false,
                editModal:false,
                temp:false,
                footerElement:false,
                selectedSubProductElement:[],
                selectedProductElement:[],
                repetition:"",
                setup:[],
                testbike:[],
                addTextField: "",
                contenttypefields:[],
                contenttypefieldsrental:[],
                fields:[],
                adde: false,
                height: 0,
                x: 0,
                y: 0,
                dateFormat: 'YYYY-MM-DD',
                styles: {'name': [],'width': [],'height': []},

                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [this.styles], preserve: true});
        },
        methods: {
            openModalEdit(index)
            { this.temp = Object.assign({}, this.campaign.setup[index]);
              this.editElement = this.campaign.setup[index];
              this.editModal = true;
            },
            expl(e,index)
            {
                if( e )
                {
                    if( typeof e !== 'undefined' ) {
                        if(e.includes("|"))
                        {
                            var stan = e.split("|");
                            var lee = stan[index];

                            return lee;
                        }
                    }
                }
                else
                    return e;
            },
            addfooter(e)
            {

                var roo = this.campaign.pagefooter;
                roo.push({"text" : this.addTextfield, "align":this.align, "size": this.selectedFontSize,"x" : this.x,"y": this.y});

                this.campaign.pagefooter = roo;
            },
            addelement()
            {


                if(this.selectedElement && this.repetition)
                {
                    this.campaign.repetitions.push({"element":this.selectedElement,"page":this.selectedPage,"repetition":this.repetition});
                }
                else
                {




              if(this.selectedProductElement)
                  this.adde = this.selectedProductElement;

              if(this.selectedElement)
              {

                  var roo = this.campaign.setup;
                  roo.push({"element" : this.selectedElement, "align":this.align, "page": this.selectedPage,  "size": this.selectedFontSize,"x" : this.x,"y": this.y});
                  this.campaign.setup = roo;

              }
             }
            },
            onDragging(x,y)
            {
               this.x = x;
               this.y = y;
            },
            changedFooterDragging(x,y,index)
            {
                this.campaign.pagefooter[index].x = x;
                this.campaign.pagefooter[index].y = y;
            },
            changedDragging(x,y,index)
            {
                this.campaign.setup[index].x = x;
                this.campaign.setup[index].y = y;

            },
            datepickerchange(e,slug)
            {
                console.log(e);
                this.campaign[slug] = [e[0]._d,e[1]._d];
            },
            onDrag: function (x, y) {
                this.x = x
                this.y = y
            },
            selector(e,slug)
            {
                let additions = [];
                additions.push({slug : slug,"elements": e});
                this.selectedSubProductElement = additions;
            },
            placelement(element,ind)
            {
 var roo = this.campaign.setup;
 if(this.selectedProductElement)
 {  roo.push({"product" : element, "align":this.align,"type":this.selectedElement, "page": this.selectedPage, "repeats": this.repetition,"size": this.selectedFontSize,"x" : this.x,"y": this.y});

     this.adde.splice(ind, 1);
 }
 else
 {
     roo.push({"product" : this.selectedElement, "align":this.align,"type":this.selectedElement, "page": this.selectedPage, "repeats": this.repetition,"size": this.selectedFontSize,"x" : this.x,"y": this.y});


 }

            },
            deleteelement(ind)
            {
                // eslint-disable-next-line no-undef
 this.campaign.setup.splice(ind, 1);
            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },
            varItems(e,slug){
                this.fieldData[slug].value = JSON.stringify(this.styles);
            },
            onChildChangeMulti(value,slug)
            {

                for(var i in value){
                    this.fieldData[slug].value = JSON.stringify(Object.values(value[i]));
                }

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            onChildChange(value,slug) {
                this.campaign[slug] = JSON.stringify(value);
            },

            handleSubmit() {

                this.campaign.image = JSON.parse(this.campaign.pdf);
                this.campaign.image["width"] = this.$refs.pageHolder.clientWidth;
                this.campaign.image["height"] = this.$refs.pageHolder.clientHeight;

                this.campaign.image = JSON.stringify(this.campaign.image);

                dataService.axiosPost("adddocument", this.campaign).then(results => {
                    if (results.data.success) {
                        this.$message.success('Nastavenia boli uložené');
                        this.fetch();
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });

            },

            fetch() {
                this.loading = true;
                dataService.axiosFetch('document',this.id).then(results => {
                    this.campaign = results;
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.fetch();
            dataService.axiosFetch("contenttypes").then(results => {
                var roleops = [];
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
                this.contenttypes = roleops;

            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .multi-items
    .element-holder
    {position:absolute;top:0;left:15px;display:inline-flex;width:auto!important;height:auto!important;}
    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-element
    {   background-color: rgba(0,0,0, 0.029);
        border-bottom:1px solid #a8a8a8;
    }
    .dynamic-element span
    {min-width:10px;
        display:block;
        min-height:18px;
    }
.subproduct
{background-color: #e1f5ff
}
.action-holder
{padding-left:5px;}
.remove-button,.edit-button
{    float: right;
    margin-left:2px;

    border-radius:0;
    padding:3px;
}
    .remove-button >>> i
    {color:red;}
    .edit-button >>> i
    {color: #34c3ce;}
    .remove-button >>> i,
    .edit-button >>> i
    {padding:2px 3px;display:block;}
    .grababble button:hover,.action-holder:hover button
    {display:block;}
.grababble button
{display:none}
.grababble:hover button
{display:block;}
    .data-element
    {display:inline-block;width:100%}
    .data-element label
    {font-weight:bold; float:left; margin-right:50px;width:25%}
    .data-element .grababble
    {display:inline-block;
        font-size:12px;
    padding:5px; border:0; float:left;}
.pdf-page
{
    position:relative;
    z-index:9;
    box-shadow: 17px 11px 30px -37px #000;
}
span.right
{display:block; width:74px; padding-top:3px;padding-right:5px;text-align:right;border:1px solid #c4c4c4;}
.pdf-page img
{width:100%;}
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button
    { position: relative;
        top: -6px;}

    .style-prop
    {float:left;width:20%;margin-right:1%;}
    .style-name
    {float:left;width:50%;
        margin-right:1%;}
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

</style>
